<template>
  <div class="app-container flex v">
    <div class="head-container">
      <!-- <quick-select
        class="filter-item"
        v-model="query.distributorId"
        filterable
        value-field="enterpriseId"
        url="api/distributor"
        :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }"
        placeholder="请选择经销商"
        @change="toQuery"
        style="width: 230px;"
        clearable
      >
        <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
      </quick-select> -->
      <el-input v-model="query.distributorName" clearable placeholder="经销商名称\编码\单号" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="申请时间起始" end-placeholder="申请时间截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 240px;" @change="toQuery" />
      <el-select v-model="query.inoutType" class="filter-item" filterable clearable placeholder="出入类型" style="width: 100px;" @change="toQuery">
        <el-option value="0" label="入账" />
        <el-option value="1" label="出账" />
      </el-select>
      <quick-select v-model="query.rebateTypeId" class="filter-item" url="api/rebateType" placeholder="返利类型" filterable clearable style="width: 150px" @change="toQuery" />
      <el-select v-model="query.status" class="filter-item" filterable clearable placeholder="状态" style="width: 130px;" @change="toQuery">
        <el-option v-for="(o, k) in statusData" :key="k" :value="k" :label="o.display" />
      </el-select>
      <el-checkbox border class="filter-item" v-model="query.expired" @change="toQuery">已过期</el-checkbox>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading"  @click="toDownload">导出</el-button>
      <el-button v-permission="['REBATEAPPLY_ALL','REBATEAPPLY_CREATE']" class="filter-item" type="primary" icon="el-icon-plus" @click="add">新建返利</el-button>
      <el-button :disabled="!selection || !selection.length" v-permission="['REBATEAPPLY_ALL','REBATEAPPLY_AUDIT']" class="filter-item" type="danger" icon="el-icon-check" @click="patchAudits" :loading="auditLoading">审批</el-button>
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">导入</el-button>
      </el-upload>
      <el-button type="primary" @click="downTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" @selection-change="handleSelectionChange" height="100px">
      <el-table-column v-if="checkPermission(['REBATEAPPLY_ALL', 'REBATEAPPLY_AUDIT'])" type="selection" width="35" align="center" :selectable="checkSelectAble" fixed></el-table-column>
      <el-table-column label="申请时间" :formatter="v=>{return new Date(v.createAt).format();}" width="140" />
      <el-table-column prop="formCode" label="单号" width="130" />
       <el-table-column prop="erpId" label="经销商编号" width="100" show-overflow-tooltip />
      <el-table-column prop="distributorName" label="经销商名称" min-width="150" show-overflow-tooltip />
      <el-table-column label="出入类型" width="70">
        <template slot-scope="scope">{{inoutTypes[scope.row.inoutType]}}</template>
      </el-table-column>
      <el-table-column prop="rebateTypeName" label="返利类型" width="80" />
      <el-table-column label="金额" width="120" align="right">
        <template slot-scope="scope">
          <span v-if="scope.row.inoutType === 0">{{$price(scope.row.money)}}</span>
          <span class="fc-e" v-else>{{$price(scope.row.money)}}</span>
        </template>
      </el-table-column>


       <el-table-column label="已用金额" width="120" align="right">
        <template slot-scope="scope">
          <div v-if="scope.row.status==1">
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.money - scope.row.usemoney-(scope.row.expireMoney||0))}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.money - scope.row.usemoney)}}</span>
          </div>
          <div v-else>
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.usemoney)}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.usemoney)}}</span>
          </div> 
        </template>
      </el-table-column>
      <el-table-column label="过期金额" width="120" align="right">
        <template slot-scope="scope">
          <!-- <div v-if="scope.row.status==1">
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.money - scope.row.usemoney-(scope.row.expireMoney||0))}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.money - scope.row.usemoney)}}</span>
          </div>
          <div v-else>
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.usemoney)}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.usemoney)}}</span>
          </div>  -->
          <span>{{$price(scope.row.expireMoney||0)}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="剩余金额" width="120" align="right">
        <template slot-scope="scope">
          <div v-if="scope.row.status==1">
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.usemoney)}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.usemoney)}}</span>
          </div>
          <div v-else>
            <span v-if="scope.row.inoutType === 0">{{$price(scope.row.money - scope.row.usemoney)}}</span>
            <span class="fc-e" v-else>{{$price(scope.row.money - scope.row.usemoney)}}</span>
          </div> 
        </template>
      </el-table-column>

      <el-table-column label="到期日期" width="90" :formatter="r => { return new Date(r.expireTime).format('yyyy-MM-dd'); }" />
      <el-table-column prop="info" label="备注" min-width="120" show-overflow-tooltip />
      <el-table-column label="状态" align="center" width="75" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="statusData[scope.row.status].type">{{statusData[scope.row.status].display}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_EDIT']" @click="edit(scope.row)" v-if="scope.row.status === 0">编辑</el-button>
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_EDIT']" @click="del(scope.row)" v-if="scope.row.status === 0">删除</el-button>
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_VIEW']" @click="edit(scope.row)" v-else>查看</el-button>
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_AUDIT']" @click="audit(scope.row)" v-if="scope.row.status === 0">审批</el-button>
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_POSTPHONE']" @click="extend(scope.row)" v-if="scope.row.status === 1 && scope.row.inoutType===0 && scope.row.isClose && scope.row.hasExpireMoney">延期退回返利</el-button>
          <el-button type="text" size="mini" v-permission="['REBATEAPPLY_ALL', 'REBATEAPPLY_POSTPHONE']" @click="extend(scope.row)" v-if="scope.row.status === 1 && scope.row.inoutType===0 && !scope.row.isClose">变更到期日期</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
    <importor ref="importor" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import config from "@/config";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Importor from "./import";
import request from "@/utils/request";

export default {
  mixins: [initData],
  components: { eForm, Importor },
  data() {
    return {
      delLoading: false,
      uploading: false,
      downloadLoading: false,
      selection: null,
      auditLoading:false,
      uploadUrl: config.hosts.request + "api/rebateApply/uploadExcel",
      uploadHeaders: {},
      statusData: [
        { display: "待审批", type: "info" },
        { display: "已通过", type: "success" },
        { display: "未通过", type: "danger" },
      ],
      inoutTypes: ["入账", "出账"],
      query: {
        distributorId: null,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.uploadHeaders.Authorization = "Bearer " + getToken();
    this.init();
  },
  methods: {
    checkPermission,
    checkSelectAble(data){
      return data.status===0;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    beforeInit() {
      this.url = "api/rebateApply/seller";
      // this.params = this.query;
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = query;
      this.params.sort = "createAt,desc";
      return true;
    },
    patchAudits(){
      
      let ids = [];
      this.selection.forEach(item => {
        ids.push(item.id);
      });
      let data = {ids:ids,result:true};
      this.$confirm(
        "您确定要审批通过所选择返利申请吗?",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        this.auditLoading = true;
        request({
            url: 'api/rebateApply/patchCheck',
            method: "put",
            data
        }).then(_=>{
          this.init();
          this.$message({
              type: "success",
              message: "审批成功!"
          });          
        }).finally(_=>{
          this.auditLoading = false;
        });
      });      
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    del(data){
      this.$confirm(
        "您确定要删除返利申请"+data.formCode +"吗?",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        request({
            url: 'api/rebateApply/'+data.id,
            method: "delete"
        }).then(_=>{
          this.init();
            this.$message({
              type: "success",
              message: "删除成功!"
            });
        });
      });
    },
    audit(data) {
      this.$refs.form && this.$refs.form.resetForm(data, true);
    },
    extend(data) {
      data = JSON.parse(JSON.stringify(data));
      data.extendExpireTime = data.expireTime;
      this.$refs.form && this.$refs.form.resetForm(data, false, "extend");
    },
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(response) {
      this.uploading = false;
      // 导入失败返回导入的错误信息
      if (response.success == false) {
        this.$refs.importor.dialogVisible = true;
        this.$refs.importor && this.$refs.importor.resetForm(response.errList);
      } else {
        // 导入成功 提示
        this.$notify({
          title: "导入成功",
          type: "success",
          duration: 2500,
        });
        this.init();
      }
    },
    handleUploadError(err) {
      if (err.status != 200) {
        this.$notify({
          title: "导入失败",
          type: "error",
          duration: 2500,
        });
      }
      this.uploading = false;
    },
    downTemplate() {
      this.downloadLoading = true;

      download("api/rebateApply/download", {})
        .then((result) => {
          downloadFile(result, "模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    toDownload(){
      if(this.data && this.data.length===0){
        this.$message.info("当前无数据导出");
        return;
      }
      this.params.page=0;
      this.params.size = this.total;
      this.downloadLoading = true;
      download('api/rebate/download', this.params).then(result => {
        downloadFile(result, '经销商返利', 'xlsx')
        this.downloadLoading = false;
      }).catch((err) => {
        this.$message.error(err.message || "操作失败，请稍候再试。");
        this.downloadLoading = false;
      })
    }
  },
};
</script>